import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import { type WorkBook } from 'xlsx';
import * as XLSX from 'xlsx';

import { AdvancedOptions } from '@/components/import/advanced-options/AdvancedOptions';
import { ConfirmImport } from '@/components/import/ConfirmImport';
import { MapColumns } from '@/components/import/MapColumns';
import { PreviewTable } from '@/components/import/PreviewTable';
import { type ColumnMeta, type RawData } from '@/components/import/types';
import { useImportStore } from '@/components/import/useImportStore';
import { ResizableCollapsiblePanel } from '@/components/layout/ResizableCollapsiblePanel';
import { ImportStep } from './types';
import {
  checkMatchingColumnName,
  getDefaultSubFieldParts,
  inferColumnTypes,
  isReadOnlyField,
  shuffleArrayInPlace
} from './utils';

type ImportProps = {
  workbook: WorkBook;
  file: File | google.picker.DocumentObject;
  step?: ImportStep;
};

function TableCell({ getValue }: { getValue: () => unknown }) {
  const sanitizedValue = DOMPurify.sanitize(getValue() as string);

  return <span dangerouslySetInnerHTML={{ __html: sanitizedValue }} />;
}

/**
 * Returns a randomly sliced subset of the input data.
 *
 * @param data - The original dataset as an array of RawData.
 * @param hasHeaderRow - Whether the first row is a header and should be excluded.
 * @param maximumNumberOfRows - The maximum number of rows to return.
 * @returns A randomly selected subset of the data, excluding the header if applicable.
 *
 * The function removes the header row if specified, then:
 * - If the remaining rows are within the limit, it returns them as-is.
 * - Otherwise, it shuffles the data and returns only the first `maximumNumberOfRows` rows.
 */
function getRandomSlicedData(data: RawData[], hasHeaderRow: boolean, maximumNumberOfRows: number) {
  const dataWithoutHeader = hasHeaderRow
    ? data.slice(1, data.length + 1)
    : data.slice(0, data.length);
  let slicedData: RawData[];

  if (dataWithoutHeader.length <= maximumNumberOfRows) {
    // If we have fewer rows than the subset limit, just use them all
    slicedData = dataWithoutHeader;
  } else {
    // If we have more rows than the subset limit, shuffle and take the first chunk
    slicedData = shuffleArrayInPlace(dataWithoutHeader).slice(0, maximumNumberOfRows);
  }
  return slicedData;
}

export function Import({ workbook, file, step = ImportStep.FieldMapping }: ImportProps) {
  const [t] = useTranslation();
  const hasFirstRowBeenCheckedRef = useRef(false);

  const {
    columns,
    previewData,
    columnVisibility,
    hasHeaderRow,
    existingTable,
    setFileName,
    setPreviewData,
    setColumns,
    setColumnsVisibility,
    setTotalRecords,
    setColumnsType,
    setColumnsFormat,
    setWorkbook,
    resetImportStore,
    setIsFileLoading,
    isFileLoading
  } = useImportStore((state) => ({
    columns: state.columns,
    previewData: state.previewData,
    columnVisibility: state.columnVisibility,
    hasHeaderRow: state.hasHeaderRow,
    existingTable: state.existingTable,
    setFileName: state.setFileName,
    setPreviewData: state.setPreviewData,
    setColumns: state.setColumns,
    setColumnsFormat: state.setColumnsFormat,
    setTotalRecords: state.setTotalRecords,
    setColumnsType: state.setColumnsType,
    setWorkbook: state.setWorkbook,
    setColumnsVisibility: state.setColumnsVisibility,
    resetImportStore: state.resetImportStore,
    getSelectedSheetIndex: state.getSelectedSheetIndex,
    setIsFileLoading: state.setIsFileLoading,
    isFileLoading: state.isFileLoading
  }));

  const selectedSheetIndex = useImportStore((state) => state.selectedSheetIndex);
  const worksheet = workbook.Sheets[workbook.SheetNames[selectedSheetIndex]];

  const data = useMemo(
    () =>
      XLSX.utils.sheet_to_json<RawData>(worksheet, {
        header: 1,
        blankrows: false,
        defval: '',
        raw: false
      }),
    [worksheet]
  );

  useEffect(() => {
    if (!data || data.length === 0) return;
    setIsFileLoading(true);
    const predictedDataSetLength = 1000;
    const multipleChoiceOptionsThreshold = 50;
    // Initialize preview data and filename
    const headerRow = hasHeaderRow ? data[0] : null;

    const slicedData = getRandomSlicedData(data, hasHeaderRow, predictedDataSetLength);

    // Initialize column visibility and type states
    const predictedColumn = inferColumnTypes(slicedData, headerRow, multipleChoiceOptionsThreshold);
    let predictedColumnTypes = predictedColumn.map((column) => column.type as string);
    const predictedColumnFormats = predictedColumn.map((column) => column.format as string);

    const selectedColumnTypes = columns.map((column) => column.meta.newFieldType);
    const selectedColumnFormats = columns.map((column) => column.meta.newFieldFormat);

    const predictionChangedAfterSwitchingHeaders =
      predictedColumn &&
      columns.some(
        (column, index) => column.meta.predictedFieldType !== predictedColumn[index]?.type
      );

    const firstRow = data[0];
    const remainingRows = data.slice(1);

    // Remove the first row if it has fewer columns than the rest of the rows (e.g. when the first row is a Table Name, Company name...etc)
    if (
      !hasFirstRowBeenCheckedRef.current &&
      data.length > 1 &&
      firstRow.length < remainingRows[0].length
    ) {
      hasFirstRowBeenCheckedRef.current = true;
      data.shift();
    }

    if (predictedColumnTypes.length === 0) {
      predictedColumnTypes = new Array(firstRow.length).fill('short_text');
    }

    if (Object.keys(columnVisibility).length === 0 && file.name) {
      setFileName(file.name.replace(/\.[^/.]+$/, ''));
    }
    // setting all columns to visible by default when switching sheets
    const columnIndices = new Array(firstRow.length).fill(true);
    setColumnsVisibility(columnIndices);
    setColumnsType(predictedColumnTypes);
    setColumnsFormat(predictedColumnFormats);

    const writableFields = existingTable?.fields.filter((field) => !isReadOnlyField(field.type));

    // Initialize column format for preview table
    const formattedColumns = firstRow.map((columnValue, index) => {
      const columnMeta = columns.find(
        (column) =>
          column.header === columnValue ||
          column.header === t('components.add_table.column_n', { index: index + 1 })
      )?.meta;

      return {
        // If the first row is already a header row, we want to use those values.
        // Otherwise, we show a generic name for the column header.
        header:
          hasHeaderRow && columnValue
            ? columnValue
            : t('components.add_table.column_n', { index: index + 1 }),
        accessorKey: index.toString(),
        meta: {
          newFieldType: predictionChangedAfterSwitchingHeaders
            ? predictedColumnTypes[index]
            : (selectedColumnTypes[index] ?? predictedColumnTypes[index]),
          newFieldFormat: selectedColumnFormats[index] ?? predictedColumnFormats[index],
          predictedFieldType: predictedColumnTypes[index],

          existingKnackField: existingTable
            ? checkMatchingColumnName(columnValue, writableFields)
            : undefined,
          parts: getDefaultSubFieldParts(
            existingTable
              ? checkMatchingColumnName(columnValue, writableFields)?.type
              : (selectedColumnTypes[index] ?? predictedColumnTypes[index])
          ),
          isThisColumnMapped: false,
          mappedColumnIndex: undefined,
          ...columnMeta
        } as ColumnMeta,
        cell: TableCell
      };
    });
    setColumns(formattedColumns);
    setPreviewData(slicedData);
    setTotalRecords(hasHeaderRow ? data.length - 1 : data.length);
    setIsFileLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedSheetIndex, hasHeaderRow]);

  useEffect(() => {
    setWorkbook(workbook);
  }, [setWorkbook, workbook]);

  useEffect(
    () => () => {
      // Set the store to initial state on component unmount
      resetImportStore();
    },
    [resetImportStore]
  );

  if (isFileLoading) {
    return null;
  }

  if (step === ImportStep.ConfirmImport) {
    return <ConfirmImport />;
  }

  return (
    <ResizableCollapsiblePanel
      title=""
      panelContent={step === ImportStep.FieldMapping ? <MapColumns /> : <AdvancedOptions />}
      mainContent={<PreviewTable columns={columns} data={previewData} />}
      autoSaveId="import-panel"
    />
  );
}
