import { useTranslation } from 'react-i18next';
import { Dialog } from '@knack/asterisk-react';

import { getChartNameFromType } from '@/pages/pages/settings-panel/view-settings/report/utils';
import { AddRecordViewFlowSteps, useAddRecordViewFlowContext } from './AddRecordViewFlowContext';

export function FlowDescription() {
  const [t] = useTranslation();

  const {
    selectedViewType,
    selectedViewSubType,
    selectedViewSource,
    isAddingChartToReportView,
    currentStep
  } = useAddRecordViewFlowContext();

  const getDescription = () => {
    if (currentStep === AddRecordViewFlowSteps.selectViewSource) {
      if (selectedViewType === 'form') {
        return '';
      }
      if (selectedViewType === 'search') {
        return t('views.add_view_dialog.search_view_select_source_table_description');
      }
      if (isAddingChartToReportView && selectedViewSubType) {
        return t('pages.element_settings.report.add_new_chart.select_source_description', {
          chartName: getChartNameFromType(selectedViewSubType)
        });
      }

      return t('views.add_view_dialog.generic_view_select_source_table_description', {
        viewName: t(`views.view_names.${selectedViewType}`)
      });
    }

    if (currentStep === AddRecordViewFlowSteps.selectViewSourcePath) {
      if (selectedViewSource?.recordDisplayQuantity === 'one') {
        if (selectedViewType === 'form') {
          return t(
            'views.add_view_dialog.form_view_select_record_display_option_description_update'
          );
        }
        return t(
          'views.add_view_dialog.generic_view_select_record_display_option_description_singular',
          {
            viewName: t(`views.view_names.${selectedViewType}`)
          }
        );
      }
      if (selectedViewType === 'form') {
        return t('views.add_view_dialog.form_view_select_record_display_option_description_add');
      }
      if (isAddingChartToReportView && selectedViewSubType) {
        return t('pages.element_settings.report.add_new_chart.select_source_path_description', {
          chartName: getChartNameFromType(selectedViewSubType)
        });
      }
      return t('views.add_view_dialog.generic_view_select_record_display_option_description', {
        viewName: t(`views.view_names.${selectedViewType}`)
      });
    }

    if (isAddingChartToReportView && selectedViewSubType) {
      return t('pages.element_settings.report.add_new_chart.confirm_description', {
        chartName: getChartNameFromType(selectedViewSubType)
      });
    }

    return t('views.add_view_dialog.confirm_add_view.description');
  };

  const description = getDescription();

  if (description) {
    return <Dialog.Description>{description}</Dialog.Description>;
  }

  return null;
}
