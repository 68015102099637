import { useTranslation } from 'react-i18next';
import { Label, Select, Switch } from '@knack/asterisk-react';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { type ConnectionField } from '@/types/schema/fields';
import { type TableViewColumn } from '@/types/schema/views/TableView';
import { usePageHelpers } from '@/hooks/helpers/usePageHelpers';
import { cn } from '@/utils/tailwind';

interface TableViewColumnSettingsConnectionProps {
  column: TableViewColumn;
  connectionField: ConnectionField;
  updateViewColumn: (updatedData: Partial<TableViewColumn>) => void;
}

export function TableViewColumnSettingsConnection({
  column,
  connectionField,
  updateViewColumn
}: TableViewColumnSettingsConnectionProps) {
  const [t] = useTranslation();
  const { getLinkableChildPagesBySourceObject } = usePageHelpers();

  const linkableChildPages = connectionField.relationship.object
    ? getLinkableChildPagesBySourceObject(connectionField.relationship.object)
    : [];

  return (
    <div className="space-y-4">
      <div>
        <div className="flex items-center gap-2">
          <Switch
            data-testid="link-to-existing-page-switch"
            checked={!!column.conn_link}
            disabled={linkableChildPages.length === 0}
            onCheckedChange={(val) => {
              if (linkableChildPages.length > 0) {
                if (val) {
                  updateViewColumn({ conn_link: linkableChildPages[0].key });
                  return;
                }
                updateViewColumn({ conn_link: '' });
              }
            }}
          />

          <Label className={cn({ 'text-subtle': linkableChildPages.length === 0 })}>
            {t(
              'pages.element_settings.table.categories.data_display.field_management.link_to_an_existing_page'
            )}
          </Label>
        </div>
        {linkableChildPages.length === 0 && (
          <p className="mt-2 text-xs text-subtle">
            {t('pages.element_settings.table.categories.data_display.field_management.link_error')}
          </p>
        )}
      </div>
      {column.conn_link && (
        <div className="ml-10">
          <Select
            defaultValue={linkableChildPages[0]?.key}
            onValueChange={(value: BuilderPage['key']) => updateViewColumn({ conn_link: value })}
          >
            <Select.Trigger className="w-full" data-testid="link-to-existing-page-select" />
            <Select.Content>
              {linkableChildPages.map((page) => (
                <Select.Item key={page.key} value={page.key}>
                  {page.label}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        </div>
      )}
    </div>
  );
}
