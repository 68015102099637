import { useCallback } from 'react';
import i18n from 'i18next';

import { type KnackViewType } from '@/types/schema/BuilderView';
import { type KnackObject } from '@/types/schema/KnackObject';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { SINGLE_RECORD_VIEW_TYPES } from '@/pages/pages/page-editor/add-view/helpers/constants';
import { type PageDataSource, type PageDataSourcePartObject } from './usePageDataSources';

export type ViewSource = {
  object: KnackObject;
  recordDisplayQuantity: 'one' | 'many';
  paths: ViewSourcePath[];
};

export type ViewSourcePath = {
  key: string;
  recordDisplayQuantity: 'one' | 'many';
  label: string;
  isAuthenticatedUser: boolean;
  relationshipType?: 'local' | 'foreign';
  sourceParts: {
    fromObject: PageDataSourcePartObject;
    toObject?: PageDataSourcePartObject;
    parentObject?: PageDataSourcePartObject;
  };
  connections?: {
    direct: ViewSourcePathConnection[];
    parent: ViewSourcePathConnection[];
  };
};

export type ViewSourcePathConnection = {
  object: {
    key: string;
    name: string;
    inflections: KnackObject['inflections'];
  };
  field: {
    key: string;
    names: {
      object: string;
      field: string;
    };
  };
  relationshipType?: 'local' | 'foreign';
};

function getViewSourcesSorted(viewSources: ViewSource[]) {
  return viewSources.sort((a, b) => {
    if (a.object.type === b.object.type) {
      return 0;
    }

    if (
      a.object.type === 'StandardObject' ||
      (a.object.type === 'UserObject' && b.object.type !== 'StandardObject')
    ) {
      return -1;
    }

    return 1;
  });
}

function getViewSourceLabel(
  viewType: KnackViewType,
  recordQuantity: 'one' | 'many',
  pageDataSourceLabelParts: ViewSourcePath['sourceParts']
) {
  // Translation function with a key prefix
  const t = i18n.getFixedT(null, null, 'views.add_view_dialog.view_source_record_display_options');

  const { fromObject, toObject, parentObject } = pageDataSourceLabelParts;

  // If there is only a 'from' object
  if (fromObject && !toObject && !parentObject) {
    if (recordQuantity === 'one') {
      if (fromObject.type === 'UserObject') {
        return t('the_logged_in_user', {
          userObjectName: fromObject.inflections.singular
        });
      }
      return t('this_page_record', {
        objectName: fromObject.inflections.singular
      });
    }

    if (viewType === 'form') {
      return t('a_new_record', {
        objectName: fromObject.inflections.singular
      });
    }

    return t('all_records', {
      objectName: fromObject.inflections.plural
    });
  }

  // If there is a 'from' and 'to' object
  if (fromObject && toObject && !parentObject) {
    if (toObject.type === 'UserObject' && toObject.origin === 'userObject') {
      if (recordQuantity === 'one') {
        return t('connections.the_object_connected_to_the_logged_in_user', {
          objectName: fromObject.inflections.singular,
          userObjectName: toObject.inflections.singular
        });
      }
      if (viewType === 'form') {
        return t('connections.a_new_object_record_connected_to_the_logged_in_user', {
          objectName: fromObject.inflections.singular,
          userObjectName: toObject.inflections.singular
        });
      }
      return t('connections.object_records_connected_to_the_logged_in_user', {
        objectName: fromObject.inflections.plural,
        userObjectName: toObject.inflections.singular
      });
    }

    if (recordQuantity === 'one') {
      return t('connections.the_object_connected_to_the_page_object', {
        objectName: fromObject.inflections.singular,
        pageObjectName: toObject.inflections.singular
      });
    }
    if (viewType === 'form') {
      return t('connections.a_new_object_record_connected_to_the_page_object', {
        objectName: fromObject.inflections.singular,
        pageObjectName: toObject.inflections.singular
      });
    }
    return t('connections.object_records_connected_to_the_page_object', {
      objectName: fromObject.inflections.plural,
      pageObjectName: toObject.inflections.singular
    });
  }

  // If there is a 'from', 'to', and 'parent' object
  if (fromObject && toObject && parentObject) {
    if (parentObject.type === 'UserObject') {
      if (recordQuantity === 'one') {
        return t(
          'connections.the_object_connected_to_the_same_object_connected_to_the_logged_in_user',
          {
            objectName: fromObject.inflections.singular,
            connectedObjectName: toObject.inflections.singular,
            userObjectName: parentObject.inflections.singular
          }
        );
      }
      if (viewType === 'form') {
        return t(
          'connections.a_new_object_record_connected_to_the_same_object_connected_to_the_logged_in_user',
          {
            objectName: fromObject.inflections.singular,
            connectedObjectName: toObject.inflections.singular,
            userObjectName: parentObject.inflections.singular
          }
        );
      }
      return t(
        'connections.object_records_connected_to_the_same_object_connected_to_the_logged_in_user',
        {
          objectName: fromObject.inflections.plural,
          connectedObjectName: toObject.inflections.singular,
          userObjectName: parentObject.inflections.singular
        }
      );
    }

    if (recordQuantity === 'one') {
      return t('connections.the_object_connected_to_the_same_object_connected_to_the_page_object', {
        objectName: fromObject.inflections.singular,
        connectedObjectName: toObject.inflections.singular,
        pageObjectName: parentObject.inflections.singular
      });
    }
    if (viewType === 'form') {
      return t(
        'connections.a_new_object_record_connected_to_the_same_object_connected_to_the_page_object',
        {
          objectName: fromObject.inflections.singular,
          connectedObjectName: toObject.inflections.singular,
          pageObjectName: parentObject.inflections.singular
        }
      );
    }
    return t(
      'connections.object_records_connected_to_the_same_object_connected_to_the_page_object',
      {
        objectName: fromObject.inflections.plural,
        connectedObjectName: toObject.inflections.singular,
        pageObjectName: parentObject.inflections.singular
      }
    );
  }

  return '';
}

export function useViewSources() {
  const { hasNumericField, hasGeocodedAddressField, hasDateTimeField } = useObjectHelpers();

  // Gets the specific sources for a view based on the view type
  const getViewSources = useCallback(
    ({
      pageDataSources,
      viewType,
      formRecordAction = 'add'
    }: {
      pageDataSources: PageDataSource[];
      viewType: KnackViewType;
      formRecordAction?: 'add' | 'update';
    }) => {
      const viewSources: ViewSource[] = [];
      const uniqueViewSourceKeys = new Set();

      pageDataSources.forEach((pageDataSource) => {
        // Set the correct record display quantity based on the view type
        const isSingleRecordView = SINGLE_RECORD_VIEW_TYPES.includes(viewType);
        let viewRecordDisplayQuantity = isSingleRecordView ? 'one' : 'many';

        // Form views are unique because they can display either 'one' or 'many' records based on the form action type (add = many, update = one)
        if (viewType === 'form') {
          viewRecordDisplayQuantity = formRecordAction === 'add' ? 'many' : 'one';
        }

        // if we need a specific records quantity, ignore non-matches
        if (pageDataSource.recordDisplayQuantity !== viewRecordDisplayQuantity) {
          return;
        }

        // Form views
        if (
          (viewType === 'form' && pageDataSource.object.type === 'EcommercePaymentObject') ||
          pageDataSource.object.type === 'EcommercePaymentMethodObject'
        ) {
          return;
        }

        // Map views
        if (viewType === 'map' && !hasGeocodedAddressField({ fromObject: pageDataSource.object })) {
          return;
        }

        // Calendar views
        if (viewType === 'calendar' && !hasDateTimeField({ fromObject: pageDataSource.object })) {
          return;
        }

        // Payment views
        if (
          viewType === 'checkout' &&
          !hasNumericField({ fromObject: pageDataSource.object }) &&
          (!pageDataSource.source.connectionKey ||
            pageDataSource.source.relationshipType !== 'local')
        ) {
          return;
        }

        // Generate the view source path key based on direct and parent connections (e.g. 'object_1:object_2:object_3')
        let viewSourcePathKey = pageDataSource.object.key;
        if (pageDataSource.connections?.direct) {
          viewSourcePathKey += `:${pageDataSource.connections.direct.object.key}`;
          if (pageDataSource.connections.parent) {
            viewSourcePathKey += `:${pageDataSource.connections.parent.object.key}`;
          }
        }

        const viewSourcePath: ViewSourcePath = {
          key: viewSourcePathKey,
          recordDisplayQuantity: pageDataSource.recordDisplayQuantity,
          label: getViewSourceLabel(
            viewType,
            pageDataSource.recordDisplayQuantity,
            pageDataSource.sourceParts
          ),
          isAuthenticatedUser: pageDataSource.type === 'user',
          sourceParts: pageDataSource.sourceParts,
          ...(pageDataSource.connections && {
            connections: {
              direct: [pageDataSource.connections.direct],
              parent: pageDataSource.connections.parent ? [pageDataSource.connections.parent] : []
            },
            relationshipType: pageDataSource.connections.direct.relationshipType
          })
        };

        // Check if the object key has already been added as a view source
        if (uniqueViewSourceKeys.has(pageDataSource.object.key)) {
          // Since there is already a source with the same object key, we don't need to add it again. Instead, we need to check if we need to update its paths
          viewSources.forEach((source) => {
            if (source.object.key !== pageDataSource.object.key) {
              return;
            }

            let isPathAlreadyAdded = false;

            // Look through the view source paths to check if it has already been added
            source.paths.forEach((path) => {
              if (path.key !== viewSourcePathKey) {
                return;
              }

              // At this point we know the path already exists, so now we just check if we need to update its direct and parent connections
              isPathAlreadyAdded = true;

              const isDirectConnectionAlreadyAdded = path.connections?.direct.some(
                (directConn) =>
                  directConn.field.key === pageDataSource.connections?.direct.field.key
              );
              const isParentConnectionAlreadyAdded = path.connections?.parent?.some(
                (parentConn) =>
                  parentConn.field.key === pageDataSource.connections?.parent?.field.key
              );

              if (path.connections && pageDataSource.connections) {
                if (!isDirectConnectionAlreadyAdded) {
                  path.connections.direct.push(pageDataSource.connections.direct);
                }
                if (pageDataSource.connections.parent && !isParentConnectionAlreadyAdded) {
                  path.connections.parent.push(pageDataSource.connections.parent);
                }
              }
            });

            // Add the path if it hasn't been added yet
            if (!isPathAlreadyAdded) {
              source.paths.push(viewSourcePath);
            }
          });

          return;
        }

        viewSources.push({
          object: pageDataSource.object,
          recordDisplayQuantity: pageDataSource.recordDisplayQuantity,
          paths: [viewSourcePath]
        });

        // Mark the data source as added, that way we can skip other sources with the same object key in future iterations
        uniqueViewSourceKeys.add(pageDataSource.object.key);
      });

      return getViewSourcesSorted(viewSources);
    },
    [hasDateTimeField, hasGeocodedAddressField, hasNumericField]
  );

  return { getViewSources };
}
