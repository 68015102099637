import { Trans, useTranslation } from 'react-i18next';
import { HiTableCells as TableIcon } from 'react-icons/hi2';
import { Badge, Divider, Input, Label, RadioGroup } from '@knack/asterisk-react';
import { produce } from 'immer';

import {
  type DetailsViewInput,
  type DetailsViewOuterColumn
} from '@/types/schema/views/DetailsView';
import { type ViewWithDetails } from '@/types/schema/views/ViewWithDetails';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { useSession } from '@/hooks/useSession';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { FieldIcon } from '@/components/FieldIcon';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { FieldInputConnectionSettings } from '@/pages/pages/settings-panel/view-settings/common/details-field-management/column-settings/FieldInputConnectionSettings';
import { FieldValueStyleSettings } from '@/pages/pages/settings-panel/view-settings/common/details-field-management/column-settings/FieldValueStyleSettings';
import { LabelFormattingSettings } from '@/pages/pages/settings-panel/view-settings/common/details-field-management/column-settings/LabelFormattingSettings';
import { ViewWithDetailsActionColumnSettings } from '@/pages/pages/settings-panel/view-settings/common/details-field-management/column-settings/ViewWithDetailsActionColumnSettings';
import { ViewDetailsRichTextInputSettings } from '@/pages/pages/settings-panel/view-settings/common/details-field-management/column-settings/ViewWithDetailsRichTextInputSettings';
import { useDetailsSettingsHelpers } from '@/pages/pages/settings-panel/view-settings/common/useDetailsSettingsHelpers';
import { useUpdateViewColumns } from '@/pages/pages/settings-panel/view-settings/useUpdateViewColumns';
import { useViewInputEditModeContext } from '@/pages/pages/settings-panel/view-settings/ViewInputEditModeContext';
import { MapSettings } from './MapSettings';

function ViewWithDetailsFieldColumnSettings({
  fieldInput,
  columns
}: {
  fieldInput: DetailsViewInput;
  columns: DetailsViewOuterColumn[];
}) {
  const [t] = useTranslation();

  const sessionData = useSession();
  const { getObjectByFieldKey } = useObjectHelpers();
  const { getFieldByKey } = useFieldHelpers();
  const updateViewColumnsSchema = useUpdateViewColumns();
  const { view } = useActiveViewContext<ViewWithDetails>();

  if (!fieldInput || fieldInput.type === 'divider' || fieldInput.type === 'special_title') {
    return null;
  }

  const columnField = getFieldByKey(fieldInput.key);
  const fieldSourceTableName = getObjectByFieldKey(fieldInput.key)?.name;

  const isActionColumn = fieldInput.type !== 'field';
  const shouldDisplayLabelStyleSettings = fieldInput.format?.label_custom;
  const shouldDisplayConnectionSettings = columnField?.type === 'connection';

  // Render only separators when the relationship is many to many
  const shouldRenderFieldValueSeparator =
    (columnField?.type === 'connection' && columnField?.relationship.has === 'many') ||
    columnField?.type === 'multiple_choice';

  const isStarterPlan = sessionData.account.productPlan.id.startsWith('starter') ?? false;
  const shouldShowMapSettings =
    (view.type === 'details' || view.type === 'list') &&
    columnField?.type === 'address' &&
    !isStarterPlan;

  const updateDetailsColumn = (updatedFieldInput: Partial<DetailsViewInput>) => {
    const updatedDetailsView = produce(columns, (draft) => {
      draft.forEach((outerColumn) => {
        outerColumn.groups.forEach((group) => {
          group.columns.forEach((column) => {
            column.forEach((input) => {
              if (input.id === fieldInput.id) {
                Object.assign(input, updatedFieldInput);
              }
            });
          });
        });
      });
    });

    updateViewColumnsSchema({ columns: updatedDetailsView });
  };

  if (isActionColumn) {
    return (
      <ViewWithDetailsActionColumnSettings
        fieldInput={fieldInput}
        updateListColumn={updateDetailsColumn}
      />
    );
  }

  return (
    <div className="mt-6">
      <div className="mb-4">
        <span className="mb-2 block">
          {t(
            'pages.element_settings.common.categories.data_display.field_management.edit_field_modal.field_source'
          )}
        </span>
        <Badge className="inline-flex items-center rounded-md">
          <TableIcon size={16} name={fieldInput.type} className="mr-1 text-subtle" />
          <Trans
            i18nKey="pages.element_settings.common.categories.data_display.field_management.edit_field_modal.field_source_description"
            values={{ tableName: fieldSourceTableName, fieldName: fieldInput.name }}
          >
            <span className="mr-1 font-semibold">{fieldInput.name}</span>
            <span className="font-semibold">{fieldSourceTableName}</span>
          </Trans>
        </Badge>
      </div>
      <div>
        <span className="mb-2 block">
          {t(
            'pages.element_settings.common.categories.data_display.field_management.edit_field_modal.field_type'
          )}
        </span>
        {columnField && (
          <Badge className="inline-flex items-center rounded-md">
            <FieldIcon name={columnField.type} size={16} className="mr-1" />
            {t(`attributes.field_types.${columnField.type}`)}
          </Badge>
        )}
      </div>
      <Divider className="my-6" />
      <div className="mb-4">
        <Label className="mb-2 block" htmlFor={`field-label-${fieldInput.key}`}>
          {t(
            'pages.element_settings.common.categories.data_display.field_management.edit_field_modal.field_label'
          )}
        </Label>
        <Input
          id={`field-label-${fieldInput.key}`}
          value={fieldInput.name}
          onChange={(e) => updateDetailsColumn({ name: e.target.value })}
          data-testid="input-field-label"
        />
      </div>

      <Divider className="my-6" />
      <BuilderAccordion.Item
        isDefaultOpen
        label={t(
          'pages.element_settings.common.categories.data_display.field_management.edit_field_modal.label_styling'
        )}
      >
        <div className="mb-4">
          <RadioGroup
            value={shouldDisplayLabelStyleSettings ? 'custom' : 'default'}
            className="mt-1 flex flex-col"
            id="label-format-options"
            data-testid="label-format-options"
            onValueChange={(value) =>
              updateDetailsColumn({
                format: { ...fieldInput.format, label_custom: value === 'custom' }
              })
            }
          >
            <RadioGroup.Container>
              <RadioGroup.Item value="default" id="radio-item-use-default-style" />
              <Label htmlFor="radio-item-use-default-style">
                {t(
                  'pages.element_settings.common.categories.data_display.field_management.edit_field_modal.use_default'
                )}
              </Label>
            </RadioGroup.Container>
            <RadioGroup.Container>
              <RadioGroup.Item
                value="custom"
                id="radio-item-use-custom-style"
                data-testid="radio-item-use-custom-style"
              />
              <Label htmlFor="radio-item-use-custom-style">
                {t(
                  'pages.element_settings.common.categories.data_display.field_management.edit_field_modal.set_custom'
                )}
              </Label>
            </RadioGroup.Container>
          </RadioGroup>
          {shouldDisplayLabelStyleSettings && (
            <LabelFormattingSettings
              fieldInput={fieldInput}
              updateListColumn={updateDetailsColumn}
            />
          )}
        </div>
      </BuilderAccordion.Item>
      <Divider className="my-6" />
      <BuilderAccordion.Item
        isDefaultOpen
        label={t(
          'pages.element_settings.common.categories.data_display.field_management.edit_field_modal.field_value_styling'
        )}
      >
        <div className="mb-4">
          <FieldValueStyleSettings fieldInput={fieldInput} updateListColumn={updateDetailsColumn} />
          {shouldRenderFieldValueSeparator && (
            <div className="mt-4 space-y-2">
              <Label className="text-subtle">
                {t(
                  'pages.element_settings.table.categories.data_display.field_management.field_value_separator'
                )}
              </Label>
              <RadioGroup
                value={fieldInput.conn_separator || 'comma'}
                onValueChange={(value: DetailsViewInput['conn_separator']) =>
                  updateDetailsColumn({ conn_separator: value })
                }
              >
                <RadioGroup.Container>
                  <RadioGroup.Item id="comma" value="comma" />
                  <Label htmlFor="comma">
                    {t(
                      'pages.element_settings.table.categories.data_display.field_management.separate_values_with_comma'
                    )}
                  </Label>
                </RadioGroup.Container>
                <RadioGroup.Container>
                  <RadioGroup.Item
                    id="newLine"
                    value="new_line"
                    data-testid="radio-item-use-new-line"
                  />
                  <Label htmlFor="newLine">
                    {t(
                      'pages.element_settings.table.categories.data_display.field_management.separate_values_with_line'
                    )}
                  </Label>
                </RadioGroup.Container>
              </RadioGroup>
            </div>
          )}

          {shouldShowMapSettings && (
            <div className="mt-4">
              <MapSettings fieldInput={fieldInput} updateDetailsColumn={updateDetailsColumn} />
            </div>
          )}
        </div>
      </BuilderAccordion.Item>
      {shouldDisplayConnectionSettings && (
        <>
          <Divider className="my-6" />
          <BuilderAccordion.Item
            className="mt-4"
            label={t(
              'pages.element_settings.table.categories.data_display.field_management.connection_settings'
            )}
            isDefaultOpen
          >
            <FieldInputConnectionSettings
              fieldInput={fieldInput}
              updateDetailsColumn={updateDetailsColumn}
              connectionField={columnField}
            />
          </BuilderAccordion.Item>
        </>
      )}
    </div>
  );
}

export function ViewWithDetailsColumnSettings({ columns }: { columns: DetailsViewOuterColumn[] }) {
  const [t] = useTranslation();

  const { viewInputId } = useViewInputEditModeContext();
  const { getViewDetailsInput } = useDetailsSettingsHelpers();

  const detailsInput = getViewDetailsInput(columns, viewInputId);

  if (!detailsInput) {
    return t('pages.element_settings.common.select_item_to_view_settings');
  }

  if (!detailsInput || detailsInput.type === 'divider') {
    return null;
  }

  if (detailsInput.type === 'special_title') {
    return <ViewDetailsRichTextInputSettings columns={columns} input={detailsInput} />;
  }

  return <ViewWithDetailsFieldColumnSettings columns={columns} fieldInput={detailsInput} />;
}
