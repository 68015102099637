import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputSearch } from '@knack/asterisk-react';

import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { type TableView, type TableViewColumn } from '@/types/schema/views/TableView';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { FieldIcon } from '@/components/FieldIcon';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useAllVisibleFields } from '@/pages/pages/settings-panel/view-settings/common/details-field-management/useAllVisibleFields';
import {
  ViewFieldListCollapsible,
  type ViewFieldInput
} from '@/pages/pages/settings-panel/view-settings/common/view-field-list-collapsible/ViewFieldListCollapsible';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

function generateTableFieldColumn(
  field: KnackField,
  connectionFieldKey?: KnackFieldKey
): TableViewColumn {
  return {
    grouping: false,
    group_sort: 'asc',
    ignore_edit: false,
    ignore_summary: false,
    conn_separator: '',
    conn_link: '',
    width: {
      type: 'default',
      units: 'px',
      amount: '50'
    },
    link_type: 'text',
    link_text: '',
    link_field: '',
    link_design_active: false,
    icon: {
      icon: '',
      align: 'left'
    },
    img_gallery: '',
    align: 'left',
    rules: [],
    id: field.key,
    field: {
      key: field.key
    },
    header: field.name,
    type: 'field',
    ...(connectionFieldKey && {
      connection: {
        key: connectionFieldKey
      }
    })
  };
}

export function TableFieldManagement() {
  const [t] = useTranslation();
  const { getObjectByKey } = useObjectHelpers();
  const { view, sourceObject } = useActiveViewContext<TableView>();
  const updateViewSchema = useUpdateView<TableView>();
  const allVisibleFields = useAllVisibleFields(sourceObject);

  const columnFieldNumber = view.columns.filter((col) => col.type === 'field').length;
  const [visibleFields, setVisibleFields] = useState<KnackField[]>(sourceObject.fields);
  const [searchValue, setSearchValue] = useState('');

  const activeViewFieldInputs: ViewFieldInput[] = [];
  view.columns.forEach((col) => {
    if (col.type !== 'field' || !col.field?.key) {
      return;
    }

    activeViewFieldInputs.push({
      id: col.id,
      fieldKey: col.field.key,
      connectionKey: col.connection?.key
    });
  });

  const onAddFieldInput = (field: KnackField, connectionFieldKey?: KnackFieldKey) => {
    updateViewSchema({
      columns: [...view.columns, generateTableFieldColumn(field, connectionFieldKey)]
    });
  };

  const onRemoveFieldInput = (field: KnackField, connectionFieldKey?: KnackFieldKey) => {
    const newColumns = view.columns.filter((col) =>
      connectionFieldKey
        ? !(col.field?.key === field.key && col.connection?.key === connectionFieldKey)
        : col.field?.key !== field.key
    );
    updateViewSchema({
      columns: newColumns
    });
  };

  const onAddAllFieldColumns = (fieldsToAdd: KnackField[], connectionFieldKey?: KnackFieldKey) => {
    const columnsToAdd: TableViewColumn[] = [];

    // Create columns for all the fields that are not already in the table
    fieldsToAdd.forEach((field) => {
      const isFieldAlreadyInView = connectionFieldKey
        ? view.columns.some(
            (input) =>
              input.field?.key === field.key && input.connection?.key === connectionFieldKey
          )
        : view.columns.some((input) => input.field?.key === field.key);

      if (isFieldAlreadyInView) {
        return;
      }

      const shouldAddColumn = connectionFieldKey
        ? !activeViewFieldInputs.some(
            (viewFieldInput) =>
              viewFieldInput.connectionKey === connectionFieldKey &&
              viewFieldInput.fieldKey === field.key
          )
        : !activeViewFieldInputs.some((column) => column.fieldKey === field.key);

      if (shouldAddColumn) {
        columnsToAdd.push(generateTableFieldColumn(field, connectionFieldKey));
      }
    });

    updateViewSchema({
      columns: [...view.columns, ...columnsToAdd]
    });
  };

  const onRemoveAllFieldColumns = (
    fieldsToRemove: KnackField[],
    connectionFieldKey?: KnackFieldKey
  ) => {
    const updatedView: Partial<TableView> = {
      ...view,
      columns: view.columns.filter((col) => {
        if (col.type !== 'field') {
          return true;
        }

        const currentColKey = col.field?.key;
        const currentColConnectionKey = col.connection?.key;

        // Fields inside connections can have the same key as other fields in other connections to the same table, so we check for the connection.key to differentiate them
        if (connectionFieldKey) {
          return !fieldsToRemove.find(
            (fieldInputToRemove) =>
              fieldInputToRemove.key === currentColKey &&
              connectionFieldKey === currentColConnectionKey
          );
        }

        return !fieldsToRemove.find(
          (fieldInputToRemove) => fieldInputToRemove.key === currentColKey
        );
      })
    };

    updateViewSchema(updatedView);
  };

  const onSearchColumn = (value: string) => {
    setSearchValue(value);

    const trimmedValue = value.trim().toLowerCase();

    if (trimmedValue === '') {
      setVisibleFields(sourceObject.fields);
      return;
    }

    setVisibleFields(
      sourceObject.fields.filter((field) => field.name.toLowerCase().includes(trimmedValue))
    );
  };

  return (
    <div className="mb-4">
      <div className="mb-2 flex items-center gap-1">
        <span className="text-xs font-medium text-emphasis">
          {t('pages.element_settings.form.categories.form_fields.field_management.manage_fields')}
        </span>
        <span className="rounded-sm bg-subtle px-1 py-0.5 text-xs font-medium text-default">
          {t(
            'pages.element_settings.form.categories.form_fields.field_management.active_inputs_count',
            {
              currentCount: columnFieldNumber,
              totalCount: allVisibleFields.length
            }
          )}
        </span>
      </div>
      <p className="mb-4 text-xs text-subtle">
        {t(
          'pages.element_settings.form.categories.form_fields.field_management.manage_fields_description'
        )}
      </p>
      <InputSearch
        className="mb-4"
        aria-label={t(
          'pages.element_settings.form.categories.form_fields.field_management.search_fields'
        )}
        placeholder={t(
          'pages.element_settings.form.categories.form_fields.field_management.search_fields'
        )}
        value={searchValue}
        onChange={(e) => onSearchColumn(e.target.value)}
      />
      <div className="flex flex-col gap-6">
        <ViewFieldListCollapsible
          dataTestId="fields-collapsible-source"
          visibleFields={visibleFields}
          activeFieldInputs={activeViewFieldInputs}
          onAddFieldInput={onAddFieldInput}
          onRemoveFieldInput={onRemoveFieldInput}
          onAddAllFieldInputs={onAddAllFieldColumns}
          onRemoveAllFieldInputs={onRemoveAllFieldColumns}
          triggerElement={<p className="text-sm">{sourceObject.name}</p>}
        />

        {sourceObject.connections.outbound.map((connection) => {
          const connObject = getObjectByKey(connection.object);

          if (!connObject) {
            return null;
          }

          const connectionFields = connObject.fields.filter((field) =>
            field.name.toLowerCase().includes(searchValue)
          );

          return (
            <ViewFieldListCollapsible
              key={`connections-outbound-${connection.key}`}
              dataTestId="fields-collapsible-outbound"
              connectionKey={connection.key}
              visibleFields={connectionFields}
              activeFieldInputs={activeViewFieldInputs}
              onAddFieldInput={onAddFieldInput}
              onRemoveFieldInput={onRemoveFieldInput}
              onAddAllFieldInputs={onAddAllFieldColumns}
              onRemoveAllFieldInputs={onRemoveAllFieldColumns}
              triggerElement={
                <>
                  <FieldIcon size={16} className="mt-1" name="connection" />
                  <div>
                    <p>{connObject.name}</p>
                    <p className="flex items-center gap-1 text-xs text-subtle">
                      <span className="py-1">{sourceObject.name}</span>
                      {'-->'}
                      <span className="py-1">{connection.name}</span>
                    </p>
                  </div>
                </>
              }
            />
          );
        })}

        {sourceObject.connections.inbound.map((connection) => {
          const connObject = getObjectByKey(connection.object);

          if (!connObject) {
            return null;
          }

          const connectionFields = connObject.fields.filter((field) =>
            field.name.toLowerCase().includes(searchValue)
          );

          return (
            <ViewFieldListCollapsible
              dataTestId="fields-collapsible-outbound"
              key={`connections-outbound-${connection.key}`}
              connectionKey={connection.key}
              visibleFields={connectionFields}
              activeFieldInputs={activeViewFieldInputs}
              onAddFieldInput={onAddFieldInput}
              onRemoveFieldInput={onRemoveFieldInput}
              onAddAllFieldInputs={onAddAllFieldColumns}
              onRemoveAllFieldInputs={onRemoveAllFieldColumns}
              triggerElement={
                <>
                  <FieldIcon size={16} className="mt-1" name="connection" />
                  <div>
                    <p>{connObject.name}</p>
                    <p className="flex items-center gap-1 text-xs text-subtle">
                      <span className="py-1">{sourceObject.name}</span>
                      {'-->'}
                      <span className="py-1">{connection.name}</span>
                    </p>
                  </div>
                </>
              }
            />
          );
        })}
      </div>
    </div>
  );
}
