import { useTranslation } from 'react-i18next';
import { Label, Select, Switch } from '@knack/asterisk-react';
import { produce } from 'immer';

import { type KnackFieldType } from '@/types/schema/KnackField';
import { type TableView, type TableViewColumn } from '@/types/schema/views/TableView';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

interface GroupingSectionProps {
  column: TableViewColumn;
  columnFieldType: KnackFieldType;
  viewColumns: TableViewColumn[];
  updateViewColumn: (column: Partial<TableViewColumn>) => void;
}

export function GroupingSection({
  column,
  columnFieldType,
  viewColumns,
  updateViewColumn
}: GroupingSectionProps) {
  const [t] = useTranslation();
  const updateViewSchema = useUpdateView<TableView>();

  const [groupedColumns, nonGroupedColumns] = viewColumns.reduce<TableViewColumn[][]>(
    (acc, col: TableViewColumn) => {
      if (col.grouping) {
        acc[0].push(col);
      } else {
        acc[1].push(col);
      }
      return acc;
    },
    [[], []]
  );
  const groupedColumnOptions = column.grouping ? groupedColumns : [...groupedColumns, column];

  const defaultPositionValue = (
    groupedColumnOptions.findIndex((groupedColumn) => groupedColumn.id === column.id) + 1
  ).toString();

  const updateGroupingPosition = (index: string) => {
    const newIndex = Number(index) - 1;

    const currentIndex = groupedColumns.findIndex(
      (groupedColumn) => groupedColumn.id === column.id
    );

    // Create a new array with the reordered columns
    const updatedGroupedColumns = produce(groupedColumns, (draft) => {
      // If the column is already in the grouped columns, we'll move it
      if (currentIndex !== -1) {
        // Remove the column from its current position
        const [movedColumn] = draft.splice(currentIndex, 1);

        // Insert it at the new position
        draft.splice(newIndex, 0, movedColumn);
      } else {
        // If it's not in the grouped columns yet, just add it
        draft.splice(newIndex, 0, column);
      }
    });

    // Update the view schema
    updateViewSchema({
      columns: [...updatedGroupedColumns, ...nonGroupedColumns]
    });
  };

  const getSortingOptions = () => {
    const basePathPrefix =
      'pages.element_settings.table.categories.data_display.field_management.grouping_sorting';

    const labels = {
      date_time: {
        asc: `${basePathPrefix}.oldest_newest`,
        desc: `${basePathPrefix}.newest_oldest`
      },
      number_like: {
        asc: `${basePathPrefix}.low_high`,
        desc: `${basePathPrefix}.high_low`
      },
      default: {
        asc: `${basePathPrefix}.a_z`,
        desc: `${basePathPrefix}.z_a`
      }
    };

    let labelSet: { asc: string; desc: string };

    if (columnFieldType === 'date_time') {
      labelSet = labels.date_time;
    } else if (['number', 'currency', 'auto_increment'].includes(columnFieldType)) {
      labelSet = labels.number_like;
    } else {
      labelSet = labels.default;
    }

    return [
      { value: 'asc', label: t(labelSet.asc) },
      { value: 'desc', label: t(labelSet.desc) }
    ] as const;
  };

  return (
    <div>
      <Label className="flex items-center gap-2">
        <Switch
          checked={column.grouping}
          onCheckedChange={(val) => {
            if (!column.group_sort) {
              updateViewColumn({ group_sort: 'asc', grouping: val });
              return;
            }

            updateViewColumn({ grouping: val });
          }}
        />
        {t(
          'pages.element_settings.common.categories.data_display.field_management.group_field_value'
        )}
      </Label>
      {column.grouping && (
        <div className="ml-10 mt-2">
          <div>
            <Label>{t('keywords.order')}</Label>
            <Select
              value={column.group_sort || 'asc'}
              onValueChange={(val: 'asc' | 'desc') => updateViewColumn({ group_sort: val })}
            >
              <Select.Trigger
                data-testid={`${column.id}-grouping-order-select-trigger`}
                className="mt-2 w-full"
              />
              <Select.Content>
                {getSortingOptions().map((option) => (
                  <Select.Item key={option.value} value={option.value}>
                    {option.label}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          </div>
          <div className="mt-2">
            <Label>{t('keywords.position')}</Label>
            <Select value={defaultPositionValue} onValueChange={updateGroupingPosition}>
              <Select.Trigger
                data-testid={`${column.id}-grouping-position-select-trigger`}
                className="mt-2 w-full"
              />
              <Select.Content>
                {groupedColumnOptions.map((groupedColumn, groupedColumnIndex) => {
                  const value = (groupedColumnIndex + 1).toString();

                  return (
                    <Select.Item key={groupedColumn.id} value={value}>
                      {value}
                    </Select.Item>
                  );
                })}
              </Select.Content>
            </Select>
          </div>
          <p className="mt-2 rounded-lg border p-2 text-xs">
            {t(
              'pages.element_settings.table.categories.data_display.field_management.grouping_helper'
            )}
          </p>
        </div>
      )}
    </div>
  );
}
