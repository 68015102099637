import { useTranslation } from 'react-i18next';

import { type TableViewColumn } from '@/types/schema/views/TableView';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { ViewColumnSettingsIconSection } from '@/pages/pages/settings-panel/view-settings/common/details-field-management/column-settings/ViewColumnSettingsIconSection';
import { ViewColumnSettingsLabelSection } from '@/pages/pages/settings-panel/view-settings/common/details-field-management/column-settings/ViewColumnSettingsLabelSection';

export function TableColumnSettingsActionButtonSection({
  columnItem,
  updateViewColumn
}: {
  columnItem: TableViewColumn;
  updateViewColumn: (column: Partial<TableViewColumn>) => void;
}) {
  const [t] = useTranslation();

  // TODO: This is a new feature that is not yet implemented. We need to wait for the design and product team to provide final thoughts.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const shouldRenderOpenLinkSwitch = columnItem.type === 'link';

  const getButtonSectionLabel = () => {
    if (columnItem.type === 'delete') {
      return t(
        'pages.element_settings.common.categories.data_display.field_management.delete_button'
      );
    }
    return t('pages.element_settings.common.categories.data_display.field_management.link_button');
  };

  return (
    <BuilderAccordion.Item label={getButtonSectionLabel()} isDefaultOpen>
      <ViewColumnSettingsLabelSection
        actionType={columnItem.type}
        fieldKey={columnItem.field?.key}
        linkText={columnItem.link_text}
        linkType={columnItem.link_type}
        onUpdate={(newOptions) => {
          const { key, ...restOptions } = newOptions;
          updateViewColumn({ ...restOptions, ...(key && { field: { key } }) });
        }}
      />
      <div className="mt-4">
        <ViewColumnSettingsIconSection
          columnIcon={columnItem.icon}
          onUpdateIcon={(icon) => {
            updateViewColumn({ icon });
          }}
          onRemoveIcon={() => updateViewColumn({ icon: null })}
        />
      </div>
      {/* {shouldRenderOpenLinkSwitch && <ColumnSettingsOpenLinkTabs />} */}
    </BuilderAccordion.Item>
  );
}
