import { useTranslation } from 'react-i18next';
import { Tabs } from '@knack/asterisk-react';

import {
  type DetailsViewInput,
  type DetailsViewInputLabelAlignment
} from '@/types/schema/views/DetailsView';

const labelFormat = {
  left: 'left',
  right: 'right',
  top: 'top',
  none: 'none'
} as const;

interface LabelFormattingSettingsProps {
  fieldInput: DetailsViewInput;
  updateListColumn: (fieldInput: Partial<DetailsViewInput>) => void;
}

export function LabelFormattingSettings({
  fieldInput,
  updateListColumn
}: LabelFormattingSettingsProps) {
  const [t] = useTranslation();

  const labelAlignmentItems = [
    {
      value: labelFormat.left,
      children: t('keywords.left')
    },
    {
      value: labelFormat.right,
      children: t('keywords.right')
    },
    {
      value: labelFormat.top,
      children: t('keywords.top')
    },
    {
      value: labelFormat.none,
      children: t('keywords.hide_label')
    }
  ];

  return (
    <div className="mt-4 flex flex-col gap-4">
      <div className="flex flex-col gap-1">
        <p className="text-subtle">
          {t(
            'pages.element_settings.common.categories.data_display.field_management.edit_field_modal.label_format_label_alignment'
          )}
        </p>
        <Tabs
          data-testid="label-alignment-tabs"
          defaultValue={fieldInput.format.label_format}
          onValueChange={(value) => {
            updateListColumn({
              format: {
                ...fieldInput.format,
                label_format: value as DetailsViewInputLabelAlignment
              }
            });
          }}
        >
          <Tabs.List items={labelAlignmentItems} shouldDisableResponsive />
        </Tabs>
      </div>
    </div>
  );
}
