import { Trans, useTranslation } from 'react-i18next';
import { Label, Select } from '@knack/asterisk-react';

import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { getChartNameFromType } from '@/pages/pages/settings-panel/view-settings/report/utils';
import { useAddRecordViewFlowContext } from './AddRecordViewFlowContext';

export function FlowConfirmContent() {
  const [t] = useTranslation();

  const {
    selectedViewSourcePath,
    selectedViewSource,
    selectedViewType,
    selectedViewSubType,
    isAddingChartToReportView,
    setEventKey,
    eventKey,
    setMapAddressKey,
    mapAddressKey
  } = useAddRecordViewFlowContext();
  const { canFieldStoreDateValues } = useFieldHelpers();

  if (!selectedViewSourcePath || !selectedViewSource || !selectedViewType) {
    return null;
  }

  let actionKey = 'displays';
  if (selectedViewType === 'form') {
    if (selectedViewSource?.recordDisplayQuantity === 'one') {
      actionKey = 'updates';
    } else {
      actionKey = 'inserts';
    }
  }

  const isCalendar = selectedViewType === 'calendar';
  const isMap = selectedViewType === 'map';

  // Lowercase the first letter
  const recordDisplayOption =
    selectedViewSourcePath.label.charAt(0).toLowerCase() + selectedViewSourcePath.label.slice(1);

  const eligibleDateTimeFieldsForCalendarEvent = isCalendar
    ? selectedViewSource.object.fields.filter((field) => canFieldStoreDateValues(field))
    : [];

  const eligibleAddressFieldsForMap = isMap
    ? selectedViewSource.object.fields.filter((field) => field.type === 'address')
    : [];

  const defaultEventKey = eligibleDateTimeFieldsForCalendarEvent?.[0]?.key;
  const defaultMapAddressKey = eligibleAddressFieldsForMap?.[0]?.key;

  if (isAddingChartToReportView && selectedViewSubType) {
    return (
      <div>
        <Trans
          i18nKey="pages.element_settings.report.add_new_chart.adding_chart_that_displays"
          values={{
            chartName: getChartNameFromType(selectedViewSubType),
            recordDisplayOption
          }}
          components={[
            <span key="0" className="font-semibold" />,
            <span key="1" className="font-semibold" />
          ]}
        />
      </div>
    );
  }

  return (
    <div data-testid="add-view-modal-confirm-text">
      <Trans
        i18nKey={`views.add_view_dialog.confirm_add_view.adding_element_that_${actionKey}`}
        values={{
          viewName: t(`views.view_names.${selectedViewType}`),
          recordDisplayOption
        }}
      >
        <span className="font-semibold">{t(`views.view_names.${selectedViewType}`)}</span>
        <span className="font-semibold">{recordDisplayOption}</span>
      </Trans>
      {(isCalendar || isMap) && (
        <div className="mt-4 space-y-2">
          <Label>
            {t(
              `views.add_view_dialog.confirm_add_view.${isCalendar ? 'select_field_for_calendar_events' : 'select_field_for_map'}`
            )}
            <Select
              onValueChange={isCalendar ? setEventKey : setMapAddressKey}
              value={
                isCalendar ? eventKey || defaultEventKey : mapAddressKey || defaultMapAddressKey
              }
            >
              <Select.Container>
                <Select.Trigger
                  placeholder={t('actions.select')}
                  className="mt-1 w-full text-subtle"
                />
                <Select.Content className="min-w-[230px]">
                  {(isCalendar
                    ? eligibleDateTimeFieldsForCalendarEvent
                    : eligibleAddressFieldsForMap
                  ).map((field) => (
                    <Select.Item value={field.key} key={field.key}>
                      {field.name}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select.Container>
            </Select>
          </Label>
        </div>
      )}
    </div>
  );
}
