import { useTranslation } from 'react-i18next';
import snakeCase from 'lodash.snakecase';

import { type KnackFilter } from '@/types/schema/KnackFilter';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { InlineKnackCriteriaValue } from '@/components/InlineKnackCriteriaValue';
import { InlineKnackField } from '@/components/InlineKnackField';

type FiltersGroupCardProps = {
  filtersCriteria: KnackFilter[];
  shouldHideEmptyDescription?: boolean;
};

function FilterGroup({ index, field, filterRule }) {
  const [t] = useTranslation();

  const firstItem = index === 0;

  return (
    <div className="my-0.5">
      {!firstItem ? <span>{t('components.rules.and_uppercase')}</span> : null}
      <InlineKnackField
        fieldName={field.name}
        fieldType={field.type}
        className="mx-1 bg-action text-emphasis"
      />
      <span>{t(`operators.${snakeCase(filterRule.operator)}`)}</span>
      <InlineKnackCriteriaValue
        criteriaField={field}
        criteria={filterRule}
        className="mx-1 bg-action py-1.5"
      />
    </div>
  );
}

export function FiltersGroupsCard({
  filtersCriteria,
  shouldHideEmptyDescription
}: FiltersGroupCardProps) {
  const [t] = useTranslation();
  const { getFieldByKey } = useFieldHelpers();

  const hasFilters = filtersCriteria.length > 0;

  if (!hasFilters && !shouldHideEmptyDescription) {
    return (
      <p>
        {t(
          'pages.element_settings.common.categories.data_display.general_settings.all_records_are_shown'
        )}
      </p>
    );
  }

  return (
    <div className="space-y-1">
      <p className="text-xs">
        {t(
          'pages.element_settings.common.categories.data_display.general_settings.show_records_where'
        )}
      </p>
      <div className="rounded-lg bg-subtle p-2" data-testid="filters-group-card">
        {filtersCriteria.map((filterRule, filterRuleIndex) => {
          const knackField = getFieldByKey(filterRule.field);

          if (!knackField) {
            return null;
          }

          return (
            <FilterGroup
              // eslint-disable-next-line react/no-array-index-key
              key={`${filterRule.field}-${filterRuleIndex}-filter`}
              field={knackField}
              filterRule={filterRule}
              index={filterRuleIndex}
            />
          );
        })}
      </div>
    </div>
  );
}
