import { useTranslation } from 'react-i18next';
import { Checkbox, Label, Switch, Tabs } from '@knack/asterisk-react';

import {
  type DetailsViewInput,
  type DetailsViewInputFieldStyles,
  type DetailsViewInputLabelHeading,
  type DetailsViewInputLabelText
} from '@/types/schema/views/DetailsView';

const fieldValueFormat = {
  bold: 'strong',
  italic: 'em',
  h1: 'h1',
  h2: 'h2'
} as const;

interface FieldValueStyleSettingsProps {
  fieldInput: DetailsViewInput;
  updateListColumn: (fieldInput: Partial<DetailsViewInput>) => void;
}

export function FieldValueStyleSettings({
  fieldInput,
  updateListColumn
}: FieldValueStyleSettingsProps) {
  const [t] = useTranslation();

  const stylesSet = new Set(fieldInput.format.styles);

  const hasHeadings = stylesSet.has('h1') || stylesSet.has('h2');
  const defaultLabelHeadingStyle = stylesSet.has('h1') ? 'h1' : 'h2';

  const labelHeaderStylesItems = [
    {
      value: fieldValueFormat.h1,
      children: t('keywords.header_h1')
    },
    {
      value: fieldValueFormat.h2,
      children: t('keywords.header_h2')
    }
  ];

  const handleHeadingStyleChange = (value: DetailsViewInputLabelHeading) => {
    let updatedStyles: DetailsViewInputFieldStyles[] = fieldInput.format.styles
      ? fieldInput.format.styles.filter((style) => style !== 'h1' && style !== 'h2')
      : [];

    switch (value) {
      case 'h1':
        updatedStyles = [...updatedStyles, 'h1'];
        break;

      case 'h2':
        updatedStyles = [...updatedStyles, 'h2'];
        break;

      default:
        break;
    }

    updateListColumn({
      format: { ...fieldInput.format, styles: updatedStyles }
    });
  };

  const handleEnableHeadings = (value: boolean) => {
    if (!value) {
      const updatedStyles: DetailsViewInputFieldStyles[] = fieldInput.format.styles
        ? fieldInput.format.styles.filter((style) => style !== 'h1' && style !== 'h2')
        : [];

      updateListColumn({
        format: { ...fieldInput.format, styles: updatedStyles }
      });

      return;
    }

    updateListColumn({
      format: { ...fieldInput.format, styles: ['h1'] }
    });
  };

  const handleTextStyleCheckboxChange = (value: DetailsViewInputLabelText) => {
    let updatedStyles: DetailsViewInputFieldStyles[] = [];
    const existingStyles = fieldInput.format.styles || [];

    if (stylesSet.has(value)) {
      updatedStyles = existingStyles.filter((style) => style !== value);
    } else {
      updatedStyles = [...existingStyles, value];
    }

    updateListColumn({
      format: { ...fieldInput.format, styles: updatedStyles }
    });
  };

  return (
    <div className="mt-4 flex flex-col gap-4">
      <div className="flex flex-col gap-1">
        <p className="text-subtle">
          {t(
            'pages.element_settings.common.categories.data_display.field_management.edit_field_modal.label_format_label_text_styling'
          )}
        </p>
        <div className="flex gap-4">
          <Label className="flex items-center gap-1 font-bold">
            <Checkbox
              checked={stylesSet.has('strong')}
              onCheckedChange={() => handleTextStyleCheckboxChange('strong')}
            />
            {t('keywords.bold')}
          </Label>
          <Label className="flex items-center gap-1 italic">
            <Checkbox
              checked={stylesSet.has('em')}
              onCheckedChange={() => handleTextStyleCheckboxChange('em')}
            />
            {t('keywords.italic')}
          </Label>
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <Label className="flex items-center gap-1 text-subtle">
          <Switch
            checked={hasHeadings}
            onCheckedChange={handleEnableHeadings}
            data-testid="heading-style-switch"
          />
          {t(
            'pages.element_settings.common.categories.data_display.field_management.edit_field_modal.label_format_label_header_styles'
          )}
        </Label>
        {hasHeadings && (
          <Tabs
            defaultValue={defaultLabelHeadingStyle}
            className="ml-8"
            data-testid="heading-style-tabs"
            onValueChange={(value) =>
              handleHeadingStyleChange(value as DetailsViewInputLabelHeading)
            }
          >
            <Tabs.List items={labelHeaderStylesItems} shouldDisableResponsive />
          </Tabs>
        )}
      </div>
    </div>
  );
}
