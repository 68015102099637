import { useTranslation } from 'react-i18next';
import { Label, MultiSelect, RadioGroup, type MultiSelectOption } from '@knack/asterisk-react';

import { type KnackObject, type KnackObjectProfileKey } from '@/types/schema/KnackObject';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';

interface UserPageAccessSettingsProps {
  userRoleObjects: KnackObject[];
}

export function UserPageAccessSettings({ userRoleObjects }: UserPageAccessSettingsProps) {
  const [t] = useTranslation();

  const { page, updatePage } = usePageEditorContext();

  const userRoleOptions: MultiSelectOption[] = userRoleObjects.map((role) => ({
    key: role.profile_key as string,
    label: role.name
  }));

  const selectedUserRoleOptions = userRoleOptions.filter((option) =>
    page.allowedProfileKeys?.includes(option.key as KnackObjectProfileKey)
  );

  const onLimitProfileAccessChange = (limitProfileAccess: 'true' | 'false') => {
    updatePage({
      action: 'update',
      type: 'page',
      pageUpdates: {
        limitProfileAccess: limitProfileAccess === 'true'
      }
    });
  };

  const onSelectUserRoles = (newSelectedOptions: MultiSelectOption[]) => {
    updatePage({
      action: 'update',
      type: 'page',
      pageUpdates: {
        allowedProfileKeys: newSelectedOptions.map((option) => option.key as KnackObjectProfileKey)
      }
    });
  };

  return (
    <>
      <h3 className="mb-2 font-medium">{t('pages.settings.access.access_control')}</h3>
      <RadioGroup
        value={String(page.limitProfileAccess || false)}
        onValueChange={onLimitProfileAccessChange}
      >
        <RadioGroup.Container>
          <RadioGroup.Item id="page-settings-limit-profile-access-false" value="false" />
          <Label htmlFor="page-settings-limit-profile-access-false">
            {t('pages.settings.access.all_logged_in_users')}
          </Label>
        </RadioGroup.Container>
        <RadioGroup.Container>
          <RadioGroup.Item id="page-settings-limit-profile-access-true" value="true" />
          <Label htmlFor="page-settings-limit-profile-access-true">
            {t('pages.settings.access.specific_user_roles_only')}
          </Label>
        </RadioGroup.Container>
      </RadioGroup>

      {page.limitProfileAccess && (
        <div className="mt-2 pl-6">
          <MultiSelect
            id="page-settings-access-user-roles"
            options={userRoleOptions}
            selectedOptions={selectedUserRoleOptions}
            onSelectOptions={onSelectUserRoles}
          />
        </div>
      )}
    </>
  );
}
